import React from "react";
import styled from "styled-components";

const LargeTitle = ({ text, subtitleHTML }) => {
  return (
    <TitleBox>
      <TitleText className="title title-huge uppercase">{text}</TitleText>
      {subtitleHTML && (
        <Subtitle
          className="subtitle"
          dangerouslySetInnerHTML={{ __html: subtitleHTML }}
        />
      )}
    </TitleBox>
  );
};

const TitleBox = styled.div`
  padding: 20pt 0 15pt 0;
  text-align: center;
`;

const TitleText = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 230%;
  line-height: 36pt;
`;

const Subtitle = styled.span`
  display: block;
  font-style: italic;
  margin-top: 5px;
`;

export default LargeTitle;
