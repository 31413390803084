import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import LargeTitle from "@components/LargeTitle";
import Layout from "@components/layout";
import { IndexIconBar } from "@components/IconBar";

import "@components/prose.css";
import "./article.css";
const remark = require("remark");
const remarkHTML = require("remark-html");

export default function Template({ data }) {
  const { body, frontmatter } = data.mdx;
  const { title, subtitle } = frontmatter;
  const subtitleHTML = remark()
    .use(remarkHTML)
    .processSync(subtitle);

  return (
    <Layout title={title} hideNav={true}>
      <div className="wrapper article-wrapper prose-container">
        <div className="inner">
          <LargeTitle text={title} subtitleHTML={subtitleHTML} />
          <IndexIconBar />
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
      }
    }
  }
`;
